<template>
    <base-dialog
        ref="exportDialog"
        v-model="opened"
        :title="title"
        :confirm-action="submit"
        :close-on-submit="false"
        confirm-icon="fal fa-cloud-upload"
        confirm-text="Export Now"
        max-width="35%"
        v-on="$listeners"
    >
        <template v-slot:activator="{ on, attrs }">
            <slot :on="on" :attrs="attrs"></slot>
        </template>
        <template v-slot:content>
            <v-card-text>
                <v-container fluid class="p-8">
                    <extended-form
                        ref="exportNowForm"
                        :form-data="form.data"
                        :disabled="saving || !hasRole(allowedRoles)"
                        :form-ready="form.ready"
                        :show-submit="false"
                        :check-dirty="false"
                    >
                        <v-row>
                            <v-col>
                                <h4 style="color: black">Choose Data Pull Date Range</h4>
                            </v-col>
                        </v-row>
                        <v-row v-if="getMissingYears">
                            <alert-message type="warning">
                                Export is not configured for the following years:
                                {{ getMissingYears }}.
                            </alert-message>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <drop-down
                                    ref="fromSchoolYear"
                                    :form="form"
                                    label="From School Year"
                                    field-key="fromSchoolYear"
                                    :items="allowedSchoolYears"
                                    hide-details="auto"
                                    prepend-inner-icon="fal fa-calendar-alt"
                                />
                                <drop-down
                                    ref="toSchoolYear"
                                    :form="form"
                                    label="To School Year"
                                    field-key="toSchoolYear"
                                    :items="allowedSchoolYears"
                                    hide-details="auto"
                                    prepend-inner-icon="fal fa-calendar-alt"
                                />
                            </v-col>
                        </v-row>
                    </extended-form>
                </v-container>
            </v-card-text>
            <v-divider />
        </template>
    </base-dialog>
</template>

<script>
import BaseDialog from '@/components/modal/BaseDialog'
import { ROLE_SETUP_EXPORT_PRODUCT_CREATE } from '@/helpers/security/roles'
import { mapGetters } from 'vuex'
import ExtendedForm from '@/components/form/ExtendedForm'
import DropDown from '@/components/form/DropDown'
import { getYearTerms } from '@/helpers/shared'
import { currentSchoolYear } from '@/helpers/form/formatting'
import api from '@/api/export'
import { errorSnackbar, info, infoSnackbar } from '@/helpers/snackbar'
import AlertMessage from '@/components/alert/AlertMessage'
import dirtyFormMixin from '@/components/form/dirtyFormMixin'

export default {
    name: 'ExportNow',
    components: { AlertMessage, DropDown, ExtendedForm, BaseDialog },
    mixins: [dirtyFormMixin],
    props: {
        product: {
            type: String,
            default: 'Product',
        },
        exportId: {
            type: Number,
            default: null,
        },
    },
    data: function () {
        return {
            opened: false,
            form: {
                data: {
                    fromSchoolYear: null,
                    toSchoolYear: null,
                },
                errors: [],
                rules: {
                    fromSchoolYear: [
                        (v) => !!v || "Must provide a value from 'From School Year'",
                        (v) => {
                            const dependantValue = this.form.data.toSchoolYear
                            const errorMsg = 'Value must not be after To School Year'

                            return !v || !dependantValue || v <= dependantValue || errorMsg
                        },
                    ],
                    toSchoolYear: [
                        (v) => !!v || "Must provide a value from 'To School Year'",
                        (v) => {
                            const dependantValue = this.form.data.fromSchoolYear
                            const errorMsg = 'Value must not be before From School Year'

                            return !v || !dependantValue || v >= dependantValue || errorMsg
                        },
                    ],
                },
                ready: false,
            },
        }
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            saving: 'export/getSaving',
            exportById: 'export/getItemById',
            exports: 'export/getItems',
        }),
        title() {
            return `Export Now | ${this.product}`
        },
        hasAccessRole() {
            return this.hasRole(ROLE_SETUP_EXPORT_PRODUCT_CREATE)
        },
        schoolYears() {
            return getYearTerms(currentSchoolYear(), 12)
        },
        allowedSchoolYears() {
            const allowed = this.schoolYears.reduce((acc, curr) => {
                const curYear = curr?.substr(0, 4)
                if (this.getExportYears[curYear]) {
                    acc[curYear] = curr
                    return acc
                }

                return acc
            }, {})

            return Object.values(allowed)
        },
        allowedRoles() {
            return [ROLE_SETUP_EXPORT_PRODUCT_CREATE]
        },
        getExportYears() {
            return this.exports.reduce((acc, curr) => {
                if (curr.schoolYear) {
                    acc[curr.schoolYear] = curr.schoolYear
                }

                return acc
            }, {})
        },
        getMissingYears() {
            const { fromSchoolYear, toSchoolYear } = this.form.data
            const fromYear = parseInt(fromSchoolYear?.substr(0, 4))
            const toYear = parseInt(toSchoolYear?.substr(0, 4))
            if (!fromYear || !toYear) {
                return null
            }

            const schoolYearMap = this.schoolYears.reduce((acc, curr) => {
                const curYear = curr?.substr(0, 4)
                acc[curYear] = curr
                return acc
            }, {})

            const missing = []
            for (let i = fromYear + 1; i < toYear; i++) {
                missing.push(schoolYearMap[i])
            }

            return missing.join(', ')
        },
    },
    watch: {
        opened: {
            handler: function (value) {
                //if the dialog is closed then set the form to not ready (which will clear dirty form check)
                this.form.ready = value
            },
        },
        form: {
            deep: true,
            handler: function (value) {
                if (value.data.fromSchoolYear && value.data.toSchoolYear) {
                    //Validate form if both years have values because they are dependant on each other
                    this.$refs.exportNowForm.validate()
                }
            },
        },
    },
    mounted() {
        const { lea, productId } = this.exportById(this.exportId)
        this.form.data.lea = lea
        this.form.data.productId = productId
    },
    methods: {
        async submit() {
            this.$refs.exportNowForm.validate()

            if (this.$refs.exportNowForm.valid) {
                const fromSchoolYear = Number(this.form.data.fromSchoolYear?.substr(0, 4))
                const toSchoolYear = Number(this.form.data.toSchoolYear?.substr(0, 4))
                const { lea, productId } = this.form.data
                let errors = false

                const data = await api.exportNow({
                    lea,
                    productId,
                    fromSchoolYear,
                    toSchoolYear,
                })

                if (data.errors) {
                    errors = true
                }

                if (errors) {
                    const subtext = 'Export failed.'
                    await errorSnackbar({ subtext })

                    return
                }

                const message = 'The export has been initiated.'
                const subtext = 'Please allow up to 10 minutes to complete the process.'
                await infoSnackbar({ message, subtext })

                this.opened = false
            }
        },
    },
}
</script>
