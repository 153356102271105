<template>
    <v-card outlined class="d-flex product-toggle" :loading="saving">
        <h5 class="d-inline-flex mr-1 ml-5 my-2 app-title">eduCLIMBER</h5>
        <v-switch
            class="d-inline-flex my-2"
            label="- Sync Database"
            inset
            :input-value="productEnabled"
            hide-details="true"
            @change="toggle"
        />
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// Educlimber Admin ETL ID.
const PRODUCT_ID = 12

export default {
    name: 'ExportEduclimber',
    computed: {
        ...mapGetters({
            district: 'getSelectedDistrict',
            productsEnabledById: 'productsEnabled/getItemById',
            saving: 'productsEnabled/getSaving',
        }),
        productEnabled: function () {
            const product = this.productsEnabledById(12, 'productId')
            if (product) {
                return product.isEnabled == 'true'
            }
            return false
        },
    },
    async mounted() {
        this.get({ productId: PRODUCT_ID, lea: this.district.lea })
    },
    methods: {
        ...mapActions({
            get: 'productsEnabled/get',
            patch: 'productsEnabled/patch',
        }),
        toggle(v) {
            this.patch({
                productId: PRODUCT_ID,
                lea: this.district.lea,
                isEnabled: v,
            })
        },
    },
}
</script>

<style scoped>
.app-title {
    line-height: 2.1;
}

.product-toggle >>> .v-label {
    width: 140px !important;
}

.product-toggle {
    width: 332px;
}
</style>
