import setVar from '@/helpers/variable-set'
import equal from 'deep-equal'

const dirtyForm = {
    data: () => ({
        formDataCopy: null,
    }),
    watch: {
        form: {
            /*
            Compare the initial form data with the new form data.
            Added extra handling for:
              * Initial value was 'null' but new value is empty string ''
              * Initial value was 'null' but new value is a boolean
            These special cases occur because the initial values were null but vuetify returned something
            else based on the field type.
           */
            handler(formData) {
                let isDirty = false

                if (!formData.ready) {
                    this.setFormDirty(isDirty)
                    return
                }

                if (!this.formDataCopy && formData.data) {
                    this.formDataCopy = { ...formData.data }
                }

                if (formData.data) {
                    //compare using deep-equal library
                    isDirty = !equal(this.formDataCopy, formData.data)
                    this.setFormDirty(isDirty)
                }
            },
            deep: true,
        },
    },
    methods: {
        setFormDirty(isDirty) {
            setVar('$dirtyForm', isDirty)
        },
    },
}

export default dirtyForm
