<template>
    <div>
        <v-menu v-model="opened" bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>fal fa-ellipsis-v</v-icon>
                </v-btn>
            </template>

            <v-list>
                <v-list-item
                    v-for="prop in routeProps"
                    :key="prop.route"
                    class="mr-2"
                    :to="prop.route"
                    :disabled="prop.disabled"
                    link
                >
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-icon small left>fal fa-pencil</v-icon>Edit
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <delete-dialog
                    v-if="params.deleteButton"
                    :delete-action="deleteItem"
                    :title="`Delete ${deleteTitle}?`"
                    @close="opened = false"
                >
                    <template v-slot:default="{ on, attrs }">
                        <v-list-item link>
                            <v-list-item-content>
                                <v-list-item-title v-bind="attrs" v-on="on">
                                    <v-icon small left>fal fa-trash</v-icon>
                                    Delete
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </delete-dialog>
                <v-list-item link>
                    <v-list-item-content>
                        <export-now
                            :product="getName"
                            :export-id="params.data.id"
                            @close="opened = false"
                        >
                            <template v-slot:default="{ on, attrs }">
                                <v-list-item-title v-bind="attrs" v-on="on">
                                    <v-icon small left>fal fa-cloud-upload</v-icon>Export Now
                                </v-list-item-title>
                            </template>
                        </export-now>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import ExportNow from '@/views/ExportManager/ExportNow'
import cfgProducts from '@/helpers/mappings/cfgProduct'
import rowRouteActionsMixins from '@/components/table/rowRouteActionsMixins'
import rowDeleteActionsMixins from '@/components/table/rowDeleteActionsMixins'
import { mapActions, mapGetters } from 'vuex'
import DeleteDialog from '@/components/table/DeleteDialog'
import { successSnackbar } from '@/helpers/snackbar'

export default {
    name: 'ExportActions',
    components: { ExportNow, DeleteDialog },
    mixins: [rowRouteActionsMixins, rowDeleteActionsMixins],
    data: () => ({
        opened: false,
    }),
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            districtId: 'getDistrictId',
        }),
        getName() {
            return cfgProducts[this.params.data.productId]?.name
        },
    },
    methods: {
        async deleteItem() {
            await this.removeByParams(this.deleteActionParams)
            await this.params.api.refreshServerSideStore({ purge: true })
            await successSnackbar({ subtext: `${this.deleteTitle} removed` })
        },
        ...mapActions({ removeByParams: 'export/removeByParams' }),
    },
}
</script>
