<template>
    <div>
        <ag-grid-server-side
            id="exportOverview"
            :column-defs="columnDefs"
            :server-side-datasource="serverSideDatasource"
            server-side-store-type="full"
            :set-grid-api="setGridApi"
        />
        <router-view />
        <v-row>
            <v-col md="4" lg="4">
                <export-educlimber />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import AgGridServerSide from '@/components/table/AgGridServerSide'
import ColumnDef from '@/helpers/ag-grid/columnDef/export'
import graphQlDataSource from '@/helpers/ag-grid/datasource/graphql'
import { mapActions, mapGetters } from 'vuex'

import ExportActions from '@/views/ExportManager/ExportActions'
import ExportEduclimber from '@/views/ExportManager/ExportEduclimber'

export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { AgGridServerSide, ExportActions, ExportEduclimber },
    props: {
        id: {
            type: String,
            default: null,
        },
        districtId: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        columnDefs: ColumnDef,
        gridApi: null,
    }),
    computed: {
        ...mapGetters({
            selectedDistrict: 'getSelectedDistrict',
            fetching: 'export/getFetching',
            saving: 'export/getSaving',
        }),
    },
    watch: {
        saving(saving) {
            // updating or adding exports triggers saving to be true while it's updating, and false when it's done.
            // we want to refresh the grid in reaction to it being finished saving.
            if (saving === false) {
                this.gridApi.refreshServerSideStore()
            }
        },
    },
    methods: {
        ...mapActions({ fetch: 'export/get' }),
        serverSideDatasource: function () {
            const fetchRows = async () => {
                await this.fetch({ lea: this.selectedDistrict.lea })
            }

            return {
                getRows: graphQlDataSource.fullStore(fetchRows, 'export/getByProduct').getRows,
            }
        },
        setGridApi: function (gridApi) {
            this.gridApi = gridApi
        },
    },
}
</script>
