var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fal fa-ellipsis-v")])],1)]}}]),model:{value:(_vm.opened),callback:function ($$v) {_vm.opened=$$v},expression:"opened"}},[_c('v-list',[_vm._l((_vm.routeProps),function(prop){return _c('v-list-item',{key:prop.route,staticClass:"mr-2",attrs:{"to":prop.route,"disabled":prop.disabled,"link":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fal fa-pencil")]),_vm._v("Edit ")],1)],1)],1)}),(_vm.params.deleteButton)?_c('delete-dialog',{attrs:{"delete-action":_vm.deleteItem,"title":("Delete " + _vm.deleteTitle + "?")},on:{"close":function($event){_vm.opened = false}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-content',[_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fal fa-trash")]),_vm._v(" Delete ")],1)],1)],1)]}}],null,false,2133123001)}):_vm._e(),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-content',[_c('export-now',{attrs:{"product":_vm.getName,"export-id":_vm.params.data.id},on:{"close":function($event){_vm.opened = false}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fal fa-cloud-upload")]),_vm._v("Export Now ")],1)]}}])})],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }