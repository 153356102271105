import { DateTime } from 'luxon'
import cfgProducts from '@/helpers/mappings/cfgProduct'
import { displayDate } from '@/helpers/form/formatting'
import { displayTime } from '@/helpers/ag-grid/cellFormats'
import { timeHeader } from '@/helpers/ag-grid/headerFormats'

export default [
    {
        headerName: 'id',
        field: 'id',
        initialHide: true,
    },
    {
        headerName: 'lea',
        field: 'lea',
        initialHide: true,
    },
    {
        headerName: 'School Year',
        field: 'schoolYear',
    },
    {
        headerName: 'Product',
        field: 'productId',
        valueFormatter: (data) =>
            cfgProducts[data.value]?.name ?? `unregistered application: ${data.value}`,
    },
    {
        headerName: 'FTP Server',
        field: 'ftpServer',
        filter: false,
    },
    {
        headerName: 'Export Path',
        field: 'exportPath',
        filter: false,
    },
    {
        headerName: 'Hold Export',
        field: 'holdExport',
        initialHide: true,
        filter: false,
    },
    {
        headerName: 'FTP Path',
        field: 'ftpPath',
        filter: false,
    },
    {
        headerName: 'FTP Username',
        field: 'ftpUserName',
        filter: false,
    },
    {
        headerName: 'FTP Password',
        field: 'ftpPassword',
        filter: false,
        // @todo: when refactoring graphql queries, make sure that these fields are role based.
        valueFormatter: (params) => (params.value ? '***********' : ''),
    },
    {
        headerName: 'FTP Port',
        field: 'ftpPort',
        filter: false,
    },
    {
        headerName: 'Sync Now',
        field: 'syncNow',
        initialHide: true,
        filter: false,
    },
    {
        headerName: timeHeader('Last Run'),
        field: 'lastRun',
        filter: false,
        valueFormatter: (date) =>
            date.value && !date.value.startsWith('1900') ? displayDate(date.value) : 'n/a',
    },
    {
        headerName: 'Last Result',
        field: 'lastResult',
        filter: false,
    },
    {
        headerName: timeHeader('Run Time'),
        field: 'runTime',
        initialHide: true,
        filter: false,
        valueFormatter: (params) => displayTime(params.value),
    },
    {
        cellRendererFramework: 'ExportActions',
        cellRendererParams: {
            linkButtons: [
                {
                    routerLinkIds: ['id'],
                    routeName: 'export-edit',
                    role: 'ROLE_SETUP_DISTRICT_EDIT',
                    btnText: 'Edit',
                    icon: 'fal fa-pencil',
                },
            ],
            deleteButton: {
                actionParams: ['lea', 'schoolYear', 'productId'],
                namespace: 'export',
                vuexMethod: 'remove',
                title: 'Export',
                roles: 'ROLE_SETUP_DISTRICT_EDIT',
            },
        },
        headerName: 'Action',
        width: 100,
        pinned: 'right',
        lockPinned: true,
        lockVisible: true,
        lockPosition: true,
        hide: false,
        suppressMenu: true,
        sortable: false,
        filter: false,
    },
]
